import React, { useState, useEffect } from "react"
import Logo from "../images/bookdood-logo.svg"
import { Link } from "gatsby"
import styled from "styled-components"
import { AuthProvider, AuthConsumer } from "./utils/AuthContext"

const NavbarScroll = () => {
  const [burger, setBurger] = useState(false)

  useEffect(() => {
    window.onscroll = function () {
      if (window.pageYOffset > 200) {
        document.querySelector(".navbar.is-fixed-top").classList.add("scrolled")
      } else {
        document
          .querySelector(".navbar.is-fixed-top")
          .classList.remove("scrolled")
      }
    }
  }, [])

  return (
    <AuthProvider>
      <AuthConsumer>
        {({ isAuth, logout, user }) => (
          <StyledNav
            className="navbar is-fixed-top"
            role="navigation"
            aria-label="main navigation"
          >
            <div className="container">
              <div className="navbar-brand">
                <Link className="navbar-item" to="/">
                  <img src={Logo} width="auto" height="24" alt="GrowKarma" style={{height: "18px"}} />
                </Link>

                <a
                  role="button"
                  className={
                    burger
                      ? "navbar-burger burger is-active"
                      : "navbar-burger burger"
                  }
                  onClick={() => setBurger(!burger)}
                >
                  <span aria-hidden="true"></span>
                  <span aria-hidden="true"></span>
                  <span aria-hidden="true"></span>
                </a>
              </div>

              <div className={burger ? "navbar-menu is-active" : "navbar-menu"}>
                <div className="navbar-end">
                  <Link to="/browse" className="navbar-item">
                    Browse catalog
                  </Link>
                  <a
                    href="https://app.bookdood.com/login/signin"
                    target="_blank"
                    className="navbar-item"
                  >
                    Login
                  </a>
                </div>
              </div>
            </div>
          </StyledNav>
        )}
      </AuthConsumer>
    </AuthProvider>
  )
}

export default NavbarScroll

const StyledNav = styled.nav`
  top: -100% !important;
  transition: all 0.35s ease-in-out;
  &.scrolled {
    top: 0 !important;
  }
`
